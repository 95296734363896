<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="450px"
  >
    <v-card tile>
      <v-card-title class="ma-0 d-flex justify-space-between">
        <span 
          class="font-weight-bold primary--text"
          :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'text-h5': $vuetify.breakpoint.xsOnly,}"
        >
          Gestión de Parámetro
        </span>
        <v-btn icon plain @click="cerrar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay
        :value="loadingApi || loading"
        color="white"
        absolute
        opacity=".8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        />
      </v-overlay>
      <v-card-text>    
        <validation-observer ref="ADMIN_PARAMS_FORM">   
          <v-row>
            <v-col cols="3" sm="2">
              <v-avatar rounded size="62" color="blue-grey lighten-5">
                <v-icon
                  size="48"
                  color="icono"
                  v-text="icons[paramsInfo.tipo] || 'mdi-file-question-outline'"
                />
              </v-avatar>
            </v-col>
            <v-col cols="9" sm="10" class="pt-2 pb-1">
              <label-form text="Tipo" required/>
              <validation-provider
                name="tipo"
                vid="paramsInfo.tipo"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                v-model="paramsInfo.tipo"
                :value="data.tipo"
                :items="tipos"
                item-text="text"
                item-value="text"
                dense
                outlined
                clearable
                :error-messages="errors[0]"
                :menu-props="{ bottom: true, offsetY: true }"
                @change="resetValor"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon color="blue-grey" v-text="item.icon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text" />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
              </validation-provider>
            </v-col>  
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0 pb-1">
              <label-form text="Clave" required />
              <validation-provider name="clave" vid="paramsInfo.clave" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="paramsInfo.clave"
                  :value="data.clave"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-fade-transition mode="out-in">              
            <v-row v-if="paramsInfo.tipo">
              <v-col cols="12" class="pt-0 pb-0">
                <label-form text="Valor" required/>
                <validation-provider
                  v-slot="{ errors }"
                  name="Valor"
                  vid="paramsInfo.valor"
                  :rules="`${paramsInfo.tipo === 'imagen' ? 'required|image' : 'required' }`"
                >
                  <template v-if="paramsInfo.tipo === 'string'">
                    <v-text-field
                      v-model="paramsInfo.valor"
                      :value="data.valor"
                      outlined
                      dense
                      :error-messages="errors[0]"
                    />
                  </template>
                  <template v-if="paramsInfo.tipo === 'imagen'">
                  <v-file-input
                      v-model="imagen"
                      placeholder="Seleccione una Imagen"
                      accept="image/*"
                      outlined
                      dense
                      :error-messages="errors[0]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          small
                          label
                          color="primary"
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </template>
                  <template v-if="paramsInfo.tipo === 'fecha'">
                    <v-text-field
                      v-model="paramsInfo.valor"
                      outlined
                      dense
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      clearable
                      :error-messages="errors[0]"
                      @click="modalCalendar = true"
                    />
                    <v-dialog
                      ref="dialog"
                      v-model="modalCalendar"
                      persistent
                      width="290px"
                    >
                      <v-date-picker
                        v-model="paramsInfo.valor"
                        scrollable
                        :min="min"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalCalendar = false"
                        >
                          Aceptar
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </template>
                  <template v-if="paramsInfo.tipo === 'hora'">
                    <v-text-field
                      v-model="paramsInfo.valor"
                      outlined
                      dense
                      prepend-inner-icon="mdi-alarm"
                      readonly
                      clearable
                      :error-messages="errors[0]"
                        @click="modalCalendar = true"
                    />
                    <v-dialog
                      ref="dialog"
                      v-model="modalCalendar"
                      persistent
                      width="290px"
                    >
                      <v-time-picker
                        v-model="paramsInfo.valor"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalCalendar = false"
                        >
                          Aceptar
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </template>
                </validation-provider>
              </v-col>
            </v-row>
          </v-fade-transition>
          <v-row>
            <v-col cols="12" class="mb-0 py-0">
              <v-list
                two-line
                class="pa-0 ma-0"
                dense
              >
                <v-list-item class="pa-0 ma-0">
                  <v-list-item-action class="mr-3">
                    <v-switch
                      v-model="paramsInfo.status"
                      color="secondary"
                      hide-details
                      :true-value="1"
                      :false-value="0"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="display font-weight-bold">Habilitar Parámetro 
                    </v-list-item-title>
                    <v-list-item-subtitle>Opción para habilitar o deshabilitar el parámetro.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </validation-observer>        
      </v-card-text>
      <v-card-actions class="d-flex justify-center pb-3">
        <v-btn
          tile
          depressed
          color="blue-grey lighten-5"
          class="px-8 mx-2"
          :small="$vuetify.breakpoint.mobile"
          @click="cerrar()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          depressed
          color="primary"
          class="px-8"
          :small="$vuetify.breakpoint.mobile"
          @click="actionParams"
        >
          {{ edit ? 'Actualizar' : 'Crear' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { createParam, updateParam } from '@/services/params'
import moment from 'moment'
const dataDefault = () => ({
  tipo: '',
  valor: '',
  clave: '',
  status: 0,
})
export default {
  name:'ModalParams',
  props:{
    value: Boolean,
    data:{
      type: Object,
      default: () => ({}),
    },
    loading:{
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      show: this.value,
      loadingApi: false,
      modalCalendar: false,
      paramsInfo: dataDefault(),
      imagen: null,
      tipos: [
        { text: 'imagen', icon: 'mdi-file-image-outline' },
        { text: 'string', icon: 'mdi-text-box-outline' },
        { text: 'fecha', icon: 'mdi-calendar-clock' },
        { text: 'hora', icon: 'mdi-clock-outline' },
      ],
      icons: {
        imagen: 'mdi-file-image-outline',
        string: 'mdi-text-box-outline',
        fecha: 'mdi-calendar-clock',
        hora: 'mdi-clock-outline',
      },
      min: moment().format("YYYY-MM-DD"),     
    }
  },
  watch: {
    show (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.show = val
    },
    data(val) {
      if(Object.values(val).length > 0) {
        this.paramsInfo = {...val }
      }
      else
        this.paramsInfo = dataDefault();     
    }
  },
  methods: {
    cerrar() {
      this.show = false
      this.paramsInfo = dataDefault()
      this.$refs.ADMIN_PARAMS_FORM.reset()
    },
    resetValor () {
      this.paramsInfo.valor = ''
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async actionParams () {
      const valid = await this.$refs.ADMIN_PARAMS_FORM.validate();
      if (valid) {

        const VALOR_PARAM = this.paramsInfo.tipo === 'imagen' 
          ? await this.getBase64(this.imagen) 
          : this.paramsInfo.valor    

        try {
          this.loadingApi = true         
          const { message } = this.edit 
            ? await updateParam({ datos: { ...this.paramsInfo, valor: VALOR_PARAM }, id: this.paramsInfo.id, })
            : await createParam({ datos: { ...this.paramsInfo, valor: VALOR_PARAM } })           
          this.cerrar()
          this.$emit('procesado')
          this.$root.$showAlert(message, 'success')
        } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error'
            );
        } finally {
          this.loadingApi = false
        }
      }
    },
  },
}
</script>
